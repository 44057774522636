import React, { useEffect, useState } from 'react';
import styles from './megaMenu.module.scss';
import cn from 'classnames';
import { CollectionWithNoImage, LeftCaratIcon } from './svgIcons';
import RenderCategory from './RenderCategory';
import { routeToCollectionPage } from '@/utils/routeToCollectionPage';
import { useSSRSelector } from '@/redux/ssrStore';

const MegaMenuDesktopView = ({ open, onClose }) => {
  const { collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
  }));

  const [highlightedCollectionId, setHighlightedCollectionId] = useState(null);

  useEffect(() => {
    if (
      highlightedCollectionId &&
      collections.length > 2 &&
      (highlightedCollectionId === collections[collections.length - 2]?.id ||
        highlightedCollectionId === collections[0]?.id)
    ) {
      const currentCollection = document.getElementById(
        `panel-collection-id-${highlightedCollectionId}`
      );

      if (currentCollection) {
        const dialogRect = currentCollection.parentElement.getBoundingClientRect();
        const elementRect = currentCollection.getBoundingClientRect();

        // Calculate the offset from the top of the dialog to the top of the element
        const offset = elementRect.top - dialogRect.top - 34;

        // Scroll the dialog by the calculated offset
        currentCollection.parentElement.scrollTo({
          top: currentCollection.parentElement.scrollTop + offset,
          behavior: 'smooth',
        });
      }
    }
  }, [highlightedCollectionId]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const collectionId = parseInt(entry.target.dataset.collectionId);
          setHighlightedCollectionId(collectionId);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: document.getElementById('collection-in-view'),
      rootMargin: '0px 0px -350px 0px',
      threshold: 0.25,
    });

    const collectionContentNodes = document.querySelectorAll('[data-collection-id]');
    collectionContentNodes.forEach((node) => observer.observe(node));

    return () => observer.disconnect();
  }, [open]);

  const handleLeftPanelCollectionClick = (e, collectionId) => {
    e.stopPropagation();
    const collectionContent = document.getElementById(`collection-${collectionId}`);

    if (collectionContent) {
      const dialogRect = collectionContent.parentElement.getBoundingClientRect();
      const elementRect = collectionContent.getBoundingClientRect();

      // Calculate the offset from the top of the dialog to the top of the element
      const offset = elementRect.top - dialogRect.top - 16;

      // Scroll the dialog by the calculated offset
      collectionContent.parentElement.scrollTo({
        top: collectionContent.parentElement.scrollTop + offset,
        behavior: 'smooth',
      });
    }
  };

  const renderCollectionNameList = () => {
    return (
      <div className={styles.collectionListWrapper}>
        {collections.map((collection) => (
          <div
            key={collection.id}
            className={cn(
              styles.collectionNameMenu,
              collection.id === highlightedCollectionId ? styles.highlighted : ''
            )}
            id={`panel-collection-id-${collection.id}`}
            onClick={(e) => handleLeftPanelCollectionClick(e, collection.id)}
          >
            <div className={styles.collectionName}>{collection.name}</div>
            <div className={styles.leftCaratIcon}>
              <LeftCaratIcon />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCollectionCategories = () => {
    return (
      <div className={styles.collectionContentWrapper} id="collection-in-view">
        {collections.map((collection) => (
          <div
            key={collection.id}
            className={styles.collectionContent}
            id={`collection-${collection.id}`}
            data-collection-id={collection.id}
          >
            <div className={styles.collectionImage}>
              {collection.image_url ? (
                <img src={collection.image_url} />
              ) : (
                <CollectionWithNoImage />
              )}
            </div>
            <div className={styles.categoriesDisplay}>
              <div
                className={styles.collectionName}
                onClick={() => {
                  routeToCollectionPage(collection);
                  onClose();
                }}
              >
                {collection.name}
              </div>
              <RenderCategory
                collectionId={collection.id}
                categories={collection.categories}
                onClose={onClose}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(styles.desktopView, open ? styles.open : '')}
      onClick={(e) => e.stopPropagation()}
    >
      {/* This div is just a hack to give box shadow as per design */}
      <div
        style={{
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
          width: '100%',
          position: 'relative',
          top: '0',
          height: '8px',
        }}
      ></div>
      <div
        style={{ width: '100%', height: '500px', background: '#fff', display: 'flex' }}
      >
        {renderCollectionNameList()}
        {renderCollectionCategories()}
      </div>
    </div>
  );
};

export default MegaMenuDesktopView;
